import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AbstractControlComponent } from '../../classes/AbstractControlComponent';
import { DatePickerType } from '../../enums/date-picker-type.enum';
import { FormStatus } from '../../enums/form-status.enum';
import { FormGroupService } from '../../services/form-group.service';

@UntilDestroy()
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'svg-frontends-date-picker-form-control',
	styleUrls: ['./date-picker-form-control.component.scss'],
	templateUrl: './date-picker-form-control.component.html',
})
export class DatePickerFormControlComponent extends AbstractControlComponent implements OnInit {
	DATE_PICKER_TYPE = DatePickerType;
	FORM_STATUS = FormStatus;

	formControl: FormControl;

	@Input() controlName: string;
	@Input() dateFilter: (date: string | null | Date) => boolean = null;
	@Input() disableControl: boolean = undefined;
	@Input() helperText?: string | TemplateRef<any>;
	@Input() label?: string | TemplateRef<any>;
	@Input() max: string | null = null;
	@Input() min: string | null = null;
	@Input() placeholder = '';
	@Input() range = false;
	@Input() disableTextInput = true;
	@Input() clearable = false;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private formGroupDirective: FormGroupDirective,
		private macFormGroupService: FormGroupService,
	) {
		super();
	}

	ngOnInit(): void {
		this.formControl = this.formGroupDirective.control.get(this.controlName) as FormControl;

		this.macFormGroupService.submit$.pipe(untilDestroyed(this)).subscribe(() => {
			FormGroupService.touchControl(this.formControl);
			this.changeDetectorRef.markForCheck();
		});
	}

	clearInput() {
		this.formControl.setValue(null);
		this.changeDetectorRef.markForCheck();
	}
}
